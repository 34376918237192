import React from "react";
import "./../index.scss";
import { Header, Footer, Contact } from "./../components";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import "./gallery.scss";
// import gif from "./../images/gallery38.gif";

export default ({ data }) => {
  return (
    <>
      <Helmet>
        <title>Showelli | Gallery</title>
        <meta
          name="description"
          content="Showelli photo gallery of our most selected photos and videos"
        />
        <meta
          name="keywords"
          content="Showelli, dancers, performers, choreography, music videos, concerts, backup dancers, gallery, photos, videos"
        />
      </Helmet>
      <Header />
      <div className="hero is-small is-primary-50 has-extra-margin-bottom">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column">
                <h2 className="title has-underline-block">Gallery</h2>
                <p>
                  These are a few of our best selected photos, see our{" "}
                  <a href="https://instagram.com/showelli_x?igshid=rv8x8o3e2duf">
                    instagram
                  </a>{" "}
                  for more.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="container">
          <div className="columns is-multiline gallery">
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery57.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery56.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery55.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery54.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery53.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery52.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery51.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery50.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery49.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery48.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery47.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery46.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery45.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery24.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery22.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery25.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery21.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery26.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery27.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery28.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery23.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery29.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery30.childImageSharp.fluid}
                alt="Showelli night cap performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery31.childImageSharp.fluid}
                alt="Showelli playhxuse performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery32.childImageSharp.fluid}
                alt="Showelli playhxuse performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery33.childImageSharp.fluid}
                alt="Showelli playhxuse performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery34.childImageSharp.fluid}
                alt="Showelli playhxuse performance"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery35.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery36.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery37.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            {/* <div className="column is-one-quarter">
              <img
                src={gif}
                alt="Playhxuse showelli"
                title="Showelli jungle customes with flowers for showelli playhxuse"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div> */}
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery39.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery40.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery41.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery42.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery43.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
            <div className="column is-one-quarter">
              <Img
                fluid={data.gallery44.childImageSharp.fluid}
                alt="Showelli hostessing girls"
                style={{ height: "100%", width: "100%", maxHeight: "auto" }}
              />
            </div>
          </div>
          <div className="has-text-centered">
            <a href="https://instagram.com/showelli_x?igshid=rv8x8o3e2duf">
              See more on instagram
            </a>
          </div>
        </div>
      </section>
      <Footer />
      <Contact />
    </>
  );
};

export const query = graphql`
  query {
    gallery21: file(relativePath: { eq: "gallery21.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery22: file(relativePath: { eq: "gallery22.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery23: file(relativePath: { eq: "gallery23.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery24: file(relativePath: { eq: "gallery24.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery25: file(relativePath: { eq: "gallery25.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery26: file(relativePath: { eq: "gallery26.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery27: file(relativePath: { eq: "gallery27.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery28: file(relativePath: { eq: "gallery28.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery29: file(relativePath: { eq: "gallery29.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery30: file(relativePath: { eq: "gallery30.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery31: file(relativePath: { eq: "gallery31.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery32: file(relativePath: { eq: "gallery32.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery33: file(relativePath: { eq: "gallery33.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery34: file(relativePath: { eq: "gallery34.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery35: file(relativePath: { eq: "gallery35.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery36: file(relativePath: { eq: "gallery36.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery37: file(relativePath: { eq: "gallery37.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery39: file(relativePath: { eq: "gallery39.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery40: file(relativePath: { eq: "gallery40.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery41: file(relativePath: { eq: "gallery41.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery42: file(relativePath: { eq: "gallery42.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery43: file(relativePath: { eq: "gallery43.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery44: file(relativePath: { eq: "gallery44.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery45: file(relativePath: { eq: "gallery45.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery46: file(relativePath: { eq: "gallery46.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery47: file(relativePath: { eq: "gallery47.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery48: file(relativePath: { eq: "gallery48.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery49: file(relativePath: { eq: "gallery49.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery50: file(relativePath: { eq: "gallery50.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery51: file(relativePath: { eq: "gallery51.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery52: file(relativePath: { eq: "gallery52.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery53: file(relativePath: { eq: "gallery53.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery54: file(relativePath: { eq: "gallery54.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery55: file(relativePath: { eq: "gallery55.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery56: file(relativePath: { eq: "gallery56.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    gallery57: file(relativePath: { eq: "gallery57.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 590) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
